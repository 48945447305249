.contract-signature--name-of-document {
  cursor: pointer;
  color: #2e61ef;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 20px;
}

.download-file-disable-tap {
  -webkit-tap-highlight-color: transparent;
}

.download-pre-text {
  opacity: 1;
  color: rgba(136, 141, 145, 1);
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.1px;
  text-align: left;
  line-height: 16px;
}

@media (max-width: 768px) {
  .download-pre-text {
    opacity: 1 !important;
    color: rgba(136, 141, 145, 1) !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    letter-spacing: -0.1px !important;
    text-align: left !important;
    line-height: 16px !important;
  }
}
