@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=block');

html {
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  @apply m-[32px] md:m-[22px] sm:m-[11px];
}
