.contract-signature--loan-agreement-ready {
  color: #13141a;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 31px;
}

.contract-signature--congratulations-you {
  color: #515657;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.contract-signature--please-take-a-moment {
  color: #515657;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.contract-signature--if-the-repayments-da {
  color: #515657;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.contract-signature--loan-agreement-ready-divider {
  height: 0.5px;
  width: 100%;
  background-color: #d2d2d2;
}

.contract-signature--contact-us {
  color: #2e61ef;
  cursor: pointer;
}
