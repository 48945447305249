.contract-signature--review-document-title {
  color: #13141a;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 31px;
}

.contract-signature--please-review-the-do {
  color: #515657;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.contract-signature--copy-of-the-signed-d {
  color: #515657;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.contract-signature--signatory-john-doe {
  color: #515657;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.contract-signature--signatory-john-doe-actor {
  color: #515657;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.25px;
}

.subtitle-contract-desktop {
  opacity: 1;
  color: rgba(19, 20, 26, 1);
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.5px;
  text-align: left;
  line-height: 33px;
}

@media (max-width: 768px) {
  .subtitle-contract-desktop {
    opacity: 1 !important;
    color: rgba(19, 20, 26, 1) !important;
    font-size: 19px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    letter-spacing: -0.5px !important;
    text-align: left !important;
  }
}
